export default {
  "text-box-1": {
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinsam zum passenden Design"])},
    "text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile-first"])},
    "text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klar, elegant"])},
    "text-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barrierefrei"])}
  },
  "text-box-2": {
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vom Design zur modernen Website"])},
    "text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue.js"])},
    "text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sass"])},
    "text-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Javascript"])},
    "text-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typo 3"])},
    "text-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopware"])}
  },
  "text-box-3": {
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Weg über die Zielgerade"])},
    "text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Früh Testen, oft Testen"])},
    "text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docker environments"])},
    "text-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CI/CD mit Gitlab"])}
  },
  "footer": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakt"])},
    "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["impressum"])},
    "mail-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe, unsere Website braucht einen neuen Anstrich!"])}
  },
  "miscellaneous": {
    "mail-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo, unsere Website soll schöner werden!"])}
  }
}