import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './plugins/fontawesome';
import isMobile from './plugins/ismobile';

import {FontAwesomeIcon} from "@/plugins/fontawesome";
import i18n from './plugins/i18n'

const app = createApp(App)
app.use(i18n)
app.use(store)
app.use(router)
app.use(isMobile)
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.mount('#app')
