<template>
  <div id="header-container">
    <h1>simply frontend.</h1>
    <language-switcher />
    <theme-switcher />
  </div>
</template>

<script>
import languageSwitcher from "@/components/languageSwitcher";
import themeSwitcher from "@/components/themeSwitcher";

export default {
  name: "MyHeader",
  components: {
    languageSwitcher,
    themeSwitcher
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/_variables.scss";
  #header-container{
    position: absolute;
    width: 100%;
    display: flex;
    > h1{
      margin-top: 20px;
      font-size: $font-size-base*2;
    }
  }
</style>