<template>
  <div
    id="welcome"
  >
    <my-header />
    <div id="bg-wrapper">
      <background-sphere
        v-for="bgSphere in calculateSpheres()"
        :key="bgSphere.id"
        :position-x="bgSphere.positionX"
        :position-y="bgSphere.positionY"
      />
    </div>
    <div id="content-wrapper">
      <div
        v-for="el in contentElement"
        :key="el.id"
        class="button-text-element"
      >
        <action-button
          v-show="!el.textShown"
          :msg="el.buttonMsg"
          @click.prevent="buttonClicked($event)"
        />
        <simple-text-box
          v-show="el.textShown"
          :classes="el.classes"
          :headline="el.textHeadline"
          :icons="el.icons"
          :texts="el.texts"
          :translated="el.translated"
        />
      </div>
      <simple-text-box
        id="impressum"
        :headline="impressum.headline"
        :icons="impressum.icons"
        :texts="impressum.texts"
        :translated="impressum.translated"
      />
    </div>
    <my-footer />
  </div>
</template>
<script>
import actionButton from '@/components/actionButton.vue';
import backgroundSphere from '@/components/backgroundSphere.vue';
import myHeader from '@/components/myHeader';
import myFooter from '@/components/myFooter';
import simpleTextBox from "@/components/simpleTextBox";

import "@fontsource/baloo-da-2";
import "@fontsource/roboto";
import "@fontsource/ruthie";
import anime from 'animejs';
import {inject} from 'vue';

export default {
  name: "Welcome",
  components: {
    simpleTextBox,
    myFooter,
    myHeader,
    actionButton,
    backgroundSphere
  },
  setup () {
    const isMobile = inject('isMobile');
    return {isMobile};
  },
  data () {
    return {
      impressum: {
        headline: "Impressum",
        translated: false,
        texts: [
          { id:1,
            text:"<h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>\n" +
                "<p>Markus Albert<br />\n" +
                "simply frontend<br />\n" +
                "Kieler Stra&szlig;e 258<br />\n" +
                "22525 Hamburg</p>\n" +
                "\n" +
                "<h3>Kontakt</h3>\n" +
                "<p>E-Mail: contact@simply-frontend.com</p><br />\n" +
                "<h3>Haftungs- und Urheberrechtshinweise</h3>\n" +
                "<p>Die Inhalte dieses Onlineangebotes wurden sorgfältig und nach unserem aktuellen Kenntnisstand erstellt, dienen jedoch nur der\n" +
                "Information und entfalten keine rechtlich bindende Wirkung, sofern es sich nicht um gesetzlich verpflichtende Informationen (z.B. das\n" +
                "Impressum, die Datenschutzerklärung, AGB oder verpflichtende Belehrungen von Verbrauchern) handelt. Wir behalten uns vor, die\n" +
                "Inhalte vollständig oder teilweise zu ändern oder zu löschen, soweit vertragliche Verpflichtungen unberührt bleiben. Alle Angebote sind\n" +
                "freibleibend und unverbindlich.</p> <br />\n" +
                "<h3>Links auf fremde Webseiten</h3>\n" +
                "<p>Inhalte fremder Webseiten, auf die wir direkt oder indirekt verweisen, liegen außerhalb unseres Verantwortungsbereiches und machen\n" +
                "wir uns nicht zu Eigen. Für alle Inhalte und insbesondere für Schäden, die aus der Nutzung der in den verlinkten Webseiten aufrufbaren\n" +
                "Informationen entstehen, haftet allein der Anbieter der verlinkten Webseiten. </p><br />\n" +
                "<h3>Urheberrechte und Markenrechte</h3>\n" +
                "<p>Alle auf dieser Website dargestellten Inhalte, wie Texte, Fotografien, Grafiken, Marken und Warenzeichen sind durch die jeweiligen\n" +
                "Schutzrechte (Urheberrechte, Markenrechte) geschützt. Die Verwendung, Vervielfältigung usw. unterliegen unseren Rechten oder den\n" +
                "Rechten der jeweiligen Urheber bzw. Rechteverwalter.</p><br />\n" +
                "<h3>Hinweise auf Rechtsverstöße</h3>\n" +
                "<p>Sollten Sie innerhalb unseres Internetauftritts Rechtsverstöße bemerken, bitten wir Sie uns auf diese hinzuweisen. Wir werden\n" +
                "rechtswidrige Inhalte und Links nach Kenntnisnahme unverzüglich entfernen.</p>" +
                "\n" +
                "<p>Quelle: <a href=\"https://www.e-recht24.de\">eRecht24</a></p>"
          }
        ]
      },
      contentElement: [
        {
          id: 1,
          classes: "text-modal first--center--italic",
          textShown: false,
          buttonMsg: "Design",
          translated: true,
          textHeadline: "Design",
          icons: [
            {id: 1, icon: ''},
            {id: 2, icon: 'mobile-alt'},
            {id: 3, icon: 'pencil-alt'},
            {id: 4, icon: ['fab', 'accessible-icon']}
          ],
          texts: [
            {id: 1, text: 'text-box-1.sub-header'},
            {id: 2, text: 'text-box-1.text-1'},
            {id: 3, text: 'text-box-1.text-2'},
            {id: 4, text: 'text-box-1.text-3'}
          ]
        },
        {
          id: 2,
          classes: "text-modal first--center--italic toolbox",
          textShown: false,
          translated: true,
          buttonMsg: "Develop",
          textHeadline: "Develop",
          icons: [
            {id: 1, icon: ''},
            {id: 2, icon: 'toolbox'},
            {id: 3, icon: ['fab', 'vuejs']},
            {id: 4, icon: ['fab', 'sass']},
            {id: 5, icon: ['fab', 'js']},
            {id: 6, icon: ['fab', 'typo3']},
            {id: 7, icon: ['fab', 'shopware']},
            {id: 8, icon: 'toolbox'}
          ],
          texts: [
            {id: 1, text: 'text-box-2.sub-header'},
            {id: 2, text: ''},
            {id: 3, text: 'text-box-2.text-1'},
            {id: 4, text: 'text-box-2.text-2'},
            {id: 5, text: 'text-box-2.text-3'},
            {id: 6, text: 'text-box-2.text-4'},
            {id: 7, text: 'text-box-2.text-5'},
            {id: 8, text: ''}
          ]
        },
        {
          id: 3,
          classes: "text-modal first--center--italic deploy",
          textShown: false,
          translated: true,
          buttonMsg: "Deploy",
          textHeadline: "Deploy",
          icons: [
            {id: 1, icon: ''},
            {id: 2, icon: 'vial'},
            {id: 3, icon: ['fab', 'docker']},
            {id: 4, icon: ['fab', 'gitlab']}
          ],
          texts: [
            {id: 1, text: 'text-box-3.sub-header'},
            {id: 2, text: 'text-box-3.text-1'},
            {id: 3, text: 'text-box-3.text-2'},
            {id: 4, text: 'text-box-3.text-3'}
          ]
        }
      ]
    };
  },
  mounted(){
    let self = this;

    let buttons = document.querySelectorAll(
        '.button-text-element .border-wrapper:first-child'
    );
    for (let btn of buttons){
      btn.style.display = 'flex';
    }
    let animation = this.showButton(buttons, 350);
    animation.finished.then(function() {
      if(self.isMobile || window.innerWidth < 992){
        self.buttonIdle(buttons, 5000, [-7,7]);
      } else {
        self.buttonIdle(buttons, 5000, [-15,15]);
      }
    });
  },
  methods: {
    calculateSpheres: function() {
      let self = this;
      let bgSpheres = [];
      if(window.innerWidth < 992){
        for (let i=0; i<5; i++) {
          let randomX = self.getRandomInt(-window.screen.width/10, window.screen.width/9);
          let randomY = self.getRandomInt(0, window.screen.height/2);
          let bgSphere = {id: i, positionX: randomX, positionY: randomY};
          bgSpheres.push(bgSphere);
        }
      } else {
        for (let i=0; i<10; i++) {
          let randomX = self.getRandomInt(-window.screen.width/10, window.screen.width/5);
          let randomY = self.getRandomInt(0, window.screen.height/2);
          let bgSphere = {id: i, positionX: randomX, positionY: randomY};
          bgSpheres.push(bgSphere);
        }
      }
      return bgSpheres;
    },
    buttonClicked: function(event){
        let el = event.target;
        el.textShown = !el.textShown;
        let self = this;
        let borderEl = el.closest('.border-wrapper');
        let buttons = document.querySelectorAll(
            '.button-text-element .border-wrapper:first-child'
        );
        let textBox = borderEl.nextElementSibling;

        anime.remove(buttons);
        let i = 1
        for(let btn of buttons){
          if(i === buttons.length){
            let animation = this.hideButton(btn, 150);
            animation.finished.then(function(){
              btn.style.display = 'none';
              textBox.style.display = 'flex';
              let textBoxMaxHeight = (window.innerHeight - 180)+'px';

              self.showTextbox(textBox, 350, textBoxMaxHeight);

              if(textBox.classList.contains('toolbox')){
                  let upperBoxWrapper = textBox.querySelector('.text-box-content:nth-of-type(2)');
                  let lowerBoxWrapper = textBox.querySelector('.text-box-content:nth-of-type(8)')
                  let svgUpper = upperBoxWrapper.querySelector('svg');
                  let svgLower = lowerBoxWrapper.querySelector('svg');

                  if(!(upperBoxWrapper.querySelector('.toolbox-label'))){
                    let boxLabelEl = document.createElement('p');
                    boxLabelEl.innerHTML = "MY TOOLBOX";
                    boxLabelEl.classList.add('toolbox-label');
                    upperBoxWrapper.appendChild(boxLabelEl);
                  }

                  svgLower.setAttribute('viewBox', '0 322 512 512');
                  svgUpper.setAttribute('viewBox', '0 -202 512 512');

                  self.toolboxIdleUpper(upperBoxWrapper);
                  self.toolboxIdleLower(lowerBoxWrapper);

                  let openToolbox = function(){self.openToolbox(upperBoxWrapper)};
                  upperBoxWrapper.setAttribute('tabindex', '0');
                  upperBoxWrapper.setAttribute('role', 'button');
                  upperBoxWrapper.setAttribute('aria-pressed', 'false');
                  upperBoxWrapper.addEventListener('click', openToolbox, false);
                  lowerBoxWrapper.addEventListener('click', openToolbox, false);
                  upperBoxWrapper.addEventListener('keypress', openToolbox, false);
                  lowerBoxWrapper.addEventListener('keypress', openToolbox, false);
              }
            });
          } else {
            let animation = this.hideButton(btn, 150);
            animation.finished.then(function(){
              btn.style.display = 'none';
            });
            i++;
          }
        }
    },
    showButton: function(el, dur){
      return anime({
        targets: el,
        opacity: 1,
        translateX: 0,
        translateY: 0,
        width: '155',
        height: '55',
        duration: dur,
        easing: 'linear',
        delay: anime.stagger(150)
      });
    },
    hideButton: function(el, dur){
      return anime({
            targets: el,
            opacity: 0,
            width: 0,
            height: 0,
            translateX: function(){
              return anime.random(-20, -15);
            },
            translateY: function(){
              return anime.random(-20, -15);
            },
            duration: dur,
            easing: 'linear'
     });
    },
    buttonIdle: function(el, dur, y){
      return anime({
        targets: el,
        duration: dur,
        direction: 'alternate',
        easing: 'easeInOutQuad',
        translateX: function(){
          return anime.random(y[0],y[1]);
        },
        translateY: function(){
          return anime.random(y[0],y[1]);
        },
        loop: true,
        opacity: 0.95,
        delay: anime.stagger(300)
      });
    },
    showTextbox: function(el, dur, maxHeight){
      return anime({
        targets: el,
        maxHeight: maxHeight,
        opacity: 1,
        duration: dur,
        easing: 'linear'
      });
    },
    showIcon: function(event){
      let target = event.target;
      let icon = target.querySelector("svg");
      if(icon){
        icon.style.display = 'inline';
        anime({
          targets: icon,
          translateX: -5,
          opacity: 1,
          duration: 350,
          easing: 'linear'
        });
      }
    },
    hideIcon: function(event){
      let target = event.target;
      let icon = target.querySelector("svg");
      if(icon){
        let animation = anime({
          targets: icon,
          translateX: 5,
          opacity: 0,
          duration: 150,
          easing: 'linear'
        });
        animation.finished.then(function(){
          icon.style.display = 'none';
        });
      }
    },
    openToolbox: function(el){
      let self = this;
      let upperToolbox = el;
      let parentWrapper = el.closest('.border-wrapper');
      let lowerToolbox = parentWrapper.querySelector('.text-box-content:nth-of-type(8)');
      if(!(upperToolbox.getAttribute('data-box-open')) || upperToolbox.getAttribute('data-box-open') === 'false'){
        anime.remove(upperToolbox);
        anime.remove(lowerToolbox);
        self.toolboxStartOpenAnims(upperToolbox, lowerToolbox);
        upperToolbox.setAttribute('data-box-open', 'true');
      } else if(upperToolbox.getAttribute('data-box-open') === 'true'){
        self.toolboxStartClosing(upperToolbox, lowerToolbox, 350);
        upperToolbox.setAttribute('data-box-open', 'false');
      }
    },
    toolboxIdleUpper: function(el){
      let tlUpper = anime.timeline({
        targets: el,
        duration: 350,
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: true
      });
      tlUpper.add({
        translateX: -5,
        translateY: -4,
        rotate: {
          value: -5
        }
      });
      tlUpper.add({
        translateX: 5,
        translateY: 8,
        rotate: {
          value: 7
        }
      });
      tlUpper.add({
        translateX: 3,
        translateY: 5,
        rotate: {
          value: -3
        }
      });
    },
    toolboxIdleLower: function(el){
      let self = this;
      if(!(self.isMobile) || window.innerWidth >= 992){
        let tlLower = anime.timeline({
          targets: el,
          duration: 350,
          easing: 'easeInOutQuad',
          direction: 'alternate',
          loop: true
        });
        tlLower.add({
          translateX: 25,
          translateY: -5,
          rotate: {
            value: -5
          }
        });
        tlLower.add({
          translateX: -35,
          translateY: 8,
          rotate: {
            value: 7
          }
        });
        tlLower.add({
          translateX: 15,
          translateY: 5,
          rotate: {
            value: -3
          }
        });
      } else if(self.isMobile || window.innerWidth < 992){
        let tlLower = anime.timeline({
          targets: el,
          duration: 350,
          easing: 'easeInOutQuad',
          direction: 'alternate',
          loop: true
        });
        tlLower.add({
          translateX: 15,
          translateY: -3,
          rotate: {
            value: -5
          }
        });
        tlLower.add({
          translateX: -15,
          translateY: 5,
          rotate: {
            value: 7
          }
        });
        tlLower.add({
          translateX: 13,
          translateY: 3,
          rotate: {
            value: -3
          }
        });
      }
    },
    toolboxStartOpenAnims: function(upperToolbox, lowerToolbox){
      let self = this;
      let parentWrapper = upperToolbox.closest('.border-wrapper');
      let toStart = anime.timeline({
        targets: [upperToolbox, lowerToolbox],
        duration: 350,
        translateX: 0,
        translateY: 0,
        rotate: 0,
        easing: 'easeOutQuad'
      });
      toStart.finished.then(function(){
        let iconElements = Array.from(parentWrapper.querySelectorAll('.text-box-content'));
        iconElements = iconElements.slice(2, -1);
        anime({
          targets: upperToolbox,
          duration: 350,
          rotation: 0,
          marginTop: 0,
          translateY: -75,
          opacity: 0.75,
          easing: 'easeInOutSine'
        });
        anime({
          targets: lowerToolbox,
          duration: 350,
          translateY: 250,
          rotation: 0,
          marginBottom: '120px',
          opacity: 0.75,
          easing: 'easeInOutSine'
        });

        for(let el of iconElements){
          let iconLabelAnim = function(){self.showIconLabel(el)};
          el.setAttribute('tabindex', '0');
          el.setAttribute('role', 'button');
          el.setAttribute('aria-pressed', 'false');
          el.addEventListener('click', iconLabelAnim, false);
          el.addEventListener('keypress', iconLabelAnim, false);
          el.classList.add('icon-visible');
        }
        anime({
          targets: iconElements,
          duration: 350,
          height: 45,
          opacity: 1,
          easing: 'easeInSine'
        });
      });
    },
    toolboxStartClosing: function(upperToolbox, lowerToolbox, dur){
      let self = this;
      let parentWrapper = upperToolbox.closest('.border-wrapper');
      let iconElements = Array.from(parentWrapper.querySelectorAll('.text-box-content'));
      iconElements = iconElements.slice(2, -1);
      for(let el of iconElements) {
        if (el.classList.contains('in-background')) {
          self.resetIconNoLabel(el, 1);
          el.classList.remove('in-background');
        } else if (el.classList.contains('label-visible')) {
          self.resetIconHideLabel(el, 1);
          el.classList.remove('label-visible');
        }
      }
      let iconAnim = anime({
        targets: iconElements,
        duration: dur,
        height: 10,
        opacity: 0,
        easing: 'easeInOutSine'
      });
      iconAnim.finished.then(function(){
        for(let el of iconElements){
          el.classList.remove('icon-visible');
          let elClone = el.cloneNode(true);
          el.parentNode.replaceChild(elClone, el);
        }
        let closeAnim = anime({
          targets: [upperToolbox],
          duration: dur,
          translateX: 0,
          translateY: 0,
          marginTop: '-100px',
          rotate: 0,
          opacity: 1,
          easing: 'easeOutQuad'
        });
        anime({
          targets: [lowerToolbox],
          duration: dur,
          translateX: 0,
          translateY: 0,
          marginBottom: '-100px',
          rotate: 0,
          opacity: 1,
          easing: 'easeOutQuad'
        });
        closeAnim.finished.then(function(){
            self.toolboxIdleUpper(upperToolbox);
            self.toolboxIdleLower(lowerToolbox);
        });
      });
    },
    showIconLabel: function(icon){
      let self = this;
      if(!(icon.classList.contains('in-background'))){
        let parentWrapper = icon.closest('.border-wrapper');
        let allIconElements = Array.from(parentWrapper.querySelectorAll('.text-box-content'));
        allIconElements = allIconElements.slice(2, -1);
        if(!(icon.classList.contains('label-visible'))){
          for(let el of allIconElements){
            if (el !== icon && !(el.classList.contains('in-background'))){
              self.iconToBackgroundNoLabel(el);
              el.classList.add('in-background');
            } else if (el === icon){
              self.highlightIconShowLabel(el);
              el.classList.add('label-visible');
            }
          }
        } else {
          for(let el of allIconElements){
            if(el.classList.contains('in-background')){
              self.resetIconNoLabel(el);
              el.classList.remove('in-background');
            } else if (el.classList.contains('label-visible')){
              self.resetIconHideLabel(el);
              el.classList.remove('label-visible');
            }
          }
        }
      }
    },
    highlightIconShowLabel: function(el, dur=350){
      let thisIconLabel = el.querySelector('p');
      anime({
        targets: el,
        duration: dur,
        easing: 'easeInOutSine',
        height: '+=30px'
      });
      anime({
        targets: thisIconLabel,
        opacity: 1,
        duration: dur,
        easing: 'easeInOutSine'
      });
    },
    resetIconHideLabel: function(el, dur=350){
      let thisIconLabel = el.querySelector("p");
      anime({
        targets: el,
        height: '-=30px',
        duration: dur,
        easing: 'easeInOutSine'
      });
      anime({
        targets: thisIconLabel,
        opacity: 0,
        duration: dur,
        easing: 'easeInOutSine'
      });
    },
    resetIconNoLabel: function(el, dur=350){
      anime({
        targets: el,
        height: '+=10px',
        opacity: 1,
        duration: dur,
        easing: 'easeInOutSine'
      });
    },
    iconToBackgroundNoLabel: function(el, dur=350){
      anime({
        targets: el,
        height: '-=10px',
        opacity: 0.5,
        duration: dur,
        easing: 'easeInOutSine'
      });
    },
    getRandomInt: function(min, max){
      return Math.floor(Math.random() * (max - min) ) + min;
    }
  }
};
</script>

<style lang="scss">
@import "./src/assets/_config.scss";
@import "./src/assets/_variables.scss";
@import "./src/assets/_mixins.scss";
@import "./src/assets/_functions.scss";

  html{
    scrollbar-color: $purple-light $black;
    scrollbar-width: thin;
  }
  ::-webkit-scrollbar{
    width: 5px;
    height: 8px;
    @each $key, $value in $themes {
      @if map-has-key($colorConfig, $key) {
        .#{$value} &{
          background-color: setStyle($colorConfig, $key, background);
        }
      } @else {
        @warn "The key ´#{$key} isn't defined in the map $config´"
      }
    }
  }
  ::-webkit-scrollbar-thumb{
    background: $purple-light;
    &:hover{
      @each $key, $value in $themes {
        @if map-has-key($colorConfig, $key) {
          .#{$value} & {
            background: setStyle($colorConfig, $key, color-primary);
          }
        } @else {
          @warn "The key ´#{$key} isn't defined in the map $config´"
        }
      }
    }
  }
  #welcome {
    margin: 0;
    width: 100%;
    @each $key, $value in $themes {
      @if map-has-key($colorConfig, $key) {
        .#{$value} &{
            background: setStyle($colorConfig, $key, background);
        }
      } @else {
        @warn "The key ´#{$key} isn't defined in the map $config´"
      }
    }
  }
  #bg-wrapper{
    position: absolute;
    z-index: -1;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  #content-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    @include break-for(medium){
      flex-direction: row;
      justify-content: space-evenly;
    }
    > p{
      font-family: map-get($font, family-head);
      font-size: $font-size-base*1.5;
      &.hidden{
        display:none;
      }
    }
  }
  #impressum{
    background-color: transparent;
    overflow-y: scroll;
    overflow-x: hidden;
    display: none;
    width: 100%;
    opacity: 0;
    max-width: 80vw;
    margin: 100px 0 10vh 0;
    @include break-for(medium){
      position:absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      max-width: 50vw;
    }
    h3 {
      font-size: $font-size-base*1.25;
      @each $key, $value in $themes {
        @if map-has-key($colorConfig, $key) {
          .#{$value} & {
            color: setStyle($colorConfig, $key, color-primary);
          }
        } @else {
          @warn "The key ´#{$key} isn't defined in the map $config´"
        }
      }
    }
    p {
      margin: 0;
      @each $key, $value in $themes {
        @if map-has-key($colorConfig, $key) {
          .#{$value} &{
            color: setStyle($colorConfig, $key, color-footer);
          }
        } @else {
          @warn "The key ´#{$key} isn't defined in the map $config´"
        }
      }
      font-size: $font-size-base*1;
    }
    .contact-link{
      display: none;
    }
    hr{
      &:nth-of-type(2){
        display: none;
      }
    }
  }
  .button-text-element{
    display: flex;
    .border-wrapper{
      &:first-child{
        width: 0;
        height: 0;
        display: none;
        opacity: 0;
      }
    }
  }
  .border-wrapper{
    @each $key, $value in $themes {
      @if map-has-key($colorConfig, $key) {
        .#{$value} &{
          background-color: setStyle($colorConfig, $key, background);
          border-image-source: setStyle($colorConfig, $key, border-gradient);
        }
      } @else {
        @warn "The key ´#{$key} isn't defined in the map $config´"
      }
    }
    border-width: 2px;
    border-image-slice: 1;
    border-style: solid;
    margin: 25px 0 25px 0;
    &.text-modal{
      width: 100%;
      height: auto;
      background-color: transparent;

      @include break-for(medium){
        min-width: 500px;
      }
      &.first--center--italic{
        > .text-box-content{
          justify-content: center;
          p{
            font-style: italic;
            padding-bottom: 20px;
          }
        }
        > .text-box-content ~ .text-box-content{
          justify-content: left;
          p{
            font-style: normal;
            padding-bottom: 0;
          }
        }
      }
    }
    &.toolbox {
      .text-box-content{
        display: none;
        &:nth-of-type(1) {
          display: flex;
        }
        &:nth-of-type(2){
          margin-top: -100px;
        }
        &:nth-of-type(3){
          margin-top: -50px;
        }
        &:nth-of-type(7){
          margin-bottom: -250px;
        }
        &:nth-of-type(8){
          margin-bottom: -100px;
        }
        &:nth-of-type(2), &:nth-of-type(8){
          display: flex;
          overflow: hidden;
          position: relative;
          justify-content: center !important;
          height: 0;
          width: 100%;
          padding: 0 0 75%;
          > svg{
            position:absolute;
            display: block;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            overflow:hidden;
          }
        }
        &.icon-visible{
          display: flex;
          flex-flow: column;
          height: 5px;
          svg{
            padding: 0;
            height: 100%;
            width: auto;
            @include break-for(medium){
              &:hover{
                cursor: pointer;
              }
            }
          }
          p{
            opacity: 0;
            margin-top: 5px;
            font-size: $font-size-base*1.25;
            font-weight: 600;
          }
        }
        @include break-for(medium){
          .fa-toolbox{
            &:hover{
              cursor: pointer;
            }
          }
        }
        .toolbox-label{
          position:absolute;
          bottom: 0;
          font-weight: 600;
          @each $key, $value in $themes {
            @if map-has-key($colorConfig, $key) {
              .#{$value} &{
                color: setStyle($colorConfig, $key, background);
              }
            } @else {
              @warn "The key ´#{$key} isn't defined in the map $config´"
            }
          }
          z-index: 1;
          @include break-for(medium){
            bottom: 20px;
            font-size: $font-size-base*1.25;
          }
        }
      }
    }
    @include break-for(medium){
      button{
        &:hover{
          cursor: pointer;
        }
      }
      .btn-close{
        &:hover, &:focus{
          cursor: pointer;
          svg{
            @each $key, $value in $themes {
              @if map-has-key($colorConfig, $key) {
                .#{$value} &{
                  stroke: setStyle($colorConfig, $key, color-hover);
                }
              } @else {
                @warn "The key ´#{$key} isn't defined in the map $config´"
              }
            }
          }
        }
      }
      &:not(.toolbox) {
        .text-box-content{
          &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4){
            margin-left: calc(50% - 130px);
          }
        }
      }
      .contact-link{
        &:hover, &:focus{
          @each $key, $value in $themes {
            @if map-has-key($colorConfig, $key) {
              .#{$value} &{
                color: setStyle($colorConfig, $key, color-hover);
              }
            } @else {
              @warn "The key ´#{$key} isn't defined in the map $config´"
            }
          }
        }
      }
    }
  }
</style>