<template>
  <svg
    v-if="renderMe"
    ref="bgSVG"
    class="bg-sphere"
    viewBox="0 0 100 100"
    :style="style"
  >
    <defs>
      <linearGradient
        id="sw-gradient"
        x1="0"
        x2="1"
        y1="1"
        y2="0"
      >
        <stop
          id="stop1"
          stop-color="rgba(0, 0, 0, 1)"
          offset="0%"
        />
        <stop
          id="stop2"
          stop-color="rgba(83.213, 0, 87.799, 1)"
          offset="90%"
        />
        <stop
          id="stop3"
          stop-color="rgba(0, 0, 0, 1)"
          offset="100%"
        />
      </linearGradient>
    </defs>
    <path
      fill="url(#sw-gradient)"
      d="
    M34.5,
    -19.9C41.3,
    -8.2,
    41,
    7.7,
    34.1,
    18.2C27.1,
    28.8,
    13.6,
    34.1,
    1.1,
    33.5C-11.4,
    32.8,
    -22.8,
    26.3,
    -28.9,
    16.2C-35,
    6,
    -36,
    -7.6,
    -30.3,
    -18.7C-24.6,
    -29.9,
    -12.3,
    -38.4,
    0.8,
    -38.9C13.9,
    -39.3,
    27.7,
    -31.7,
    34.5,
    -19.9Z
    "
      transform="translate(50 50)"
      stroke-width="0"
      stroke="url(#sw-gradient)"
    />
  </svg>
</template>

<script>
import anime from 'animejs';
import {nextTick, ref} from "vue";

function onIdle(cb = () => {}) {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(cb);
  } else {
    setTimeout(() => {
      nextTick(cb);
    }, 300);
  }
}

export default {
  name: "BackgroundSphere",
  props: {
    positionX: {
      type: Number,
      default: 0
    },
    positionY: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const renderMe = ref(false);

    onIdle(function(){
      renderMe.value = true;
      stop();
    });

    return { renderMe }
  },
  computed: {
    style() {
      return 'transform: translate('+this.positionX+'px, '+this.positionY+'px )';
    }
  },
  updated(){
    this.backgroundSphereIdle(this.$refs.bgSVG)
  },
  methods: {
    backgroundSphereIdle: function(el) {
      let self = this;
      if(window.screen.width < 992){
        anime({
          targets: el,
          translateX: function() {
            return anime.random(-200, 200);
          },
          translateY: function() {
            return anime.random(-250, 1200);
          },
          rotation: function(){
            return anime.random(-50, 50);
          },
          opacity: function(){
            let opac = Math.random();
            if(opac > 0.65){
              opac = opac - Math.random();
              while(opac < 0){
                opac = Math.random();
              }
            }
            return opac;
          },
          duration: function(){
            return anime.random(9000,18000);
          },
          delay: function(){
            return anime.random(400, 12500);
          },
          loop: true,
          direction: 'alternate',
          easing: 'easeInOutSine'
        });
      } else {
        anime({
          targets: el,
          translateX: function() {
            return anime.random(-500, 500);
          },
          translateY: function() {
            return anime.random(-250, 1300);
          },
          rotation: function(){
            return anime.random(-50, 50);
          },
          opacity: function(){
            let opac = Math.random();
            if(opac > 0.65){
              opac = opac - Math.random();
              while(opac < 0){
                opac = Math.random();
              }
            }
            return opac;
          },
          duration: function(){
            return anime.random(9000,18000);
          },
          delay: function(){
            return anime.random(400, 12500);
          },
          loop: true,
          direction: 'alternate',
          easing: 'easeInOutSine'
        });
      }
    }
  },
}
</script>

<style scoped>
.bg-sphere{
  opacity: 0;
  max-width: 50px;
  max-height: 50px;
}
</style>