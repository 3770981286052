<template>
  <button
    class="theme-switcher"
    tabindex="0"
    aria-pressed="false"
    @click.prevent="switchTheme()"
    @keypress="switchTheme()"
  >
    <font-awesome-icon
      v-if="activeTheme === 'dark'"
      icon="cloud-sun"
    />
    <font-awesome-icon
      v-if="activeTheme === 'light'"
      :icon="['fab', 'ubuntu']"
    />
    <font-awesome-icon
      v-if="activeTheme === 'ubuntu'"
      icon="cloud-moon"
    />
  </button>
</template>

<script>
export default {
  name: "ThemeSwitcher",
  data (){
    return { activeTheme: "dark" }
  },
  methods: {
    switchTheme: function(){
      let body = document.querySelector("body");
      let self = this;
      switch (self.activeTheme){
        case 'dark':
          body.classList.remove("theme-dark");
          body.classList.add("theme-light");
          self.activeTheme = "light";
          break;
        case 'light':
          body.classList.remove("theme-light");
          body.classList.add("theme-ubuntu");
          self.activeTheme = "ubuntu";
          break;
        case 'ubuntu':
          body.classList.remove("theme-ubuntu");
          body.classList.add("theme-dark");
          self.activeTheme = "dark";
          break;
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/_variables.scss";
@import "./src/assets/_config.scss";
@import "./src/assets/_mixins.scss";
@import "./src/assets/_functions.scss";

.theme-switcher{
  border: none;
  background: transparent;
  padding: 0;
  min-height: 42px;
  @each $key, $value in $themes {
    @if map-has-key($colorConfig, $key) {
      .#{$value} & {
        color: setStyle($colorConfig, $key, color-primary);
      }
    } @else {
      @warn "The key ´#{$key} isn't defined in the map $config´"
    }
  }
  display:flex;
  margin-left: auto;
  margin-right: 30px;
  margin-top: 60px;
  svg{
    margin-top: 10px;
    width: auto;
    height: 18px;
    @include break-for(medium){
      &:hover{
        cursor:pointer;
        @each $key, $value in $themes {
          @if map-has-key($colorConfig, $key) {
            .#{$value} & {
              color: setStyle($colorConfig, $key, color-hover);
            }
          } @else {
            @warn "The key ´#{$key} isn't defined in the map $config´"
          }
        }
      }
    }
  }
}

</style>