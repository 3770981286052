<template>
  <div
    class="border-wrapper"
    :class="classes"
  >
    <button
      class="btn-close"
      tabindex="0"
      @click.prevent="closeTextbox($event)"
      @keypress="closeTextbox($event)"
    >
      <svg viewBox="0 0 100 100">
        <path
          d="M 10,10 l 90,90 M 100,10 l -90,90"
          stroke-width="10"
        />
      </svg>
    </button>
    <h2>{{ headline }}</h2>
    <hr>
    <div
      v-for="el in texts"
      :key="el.id"
      class="text-box-content"
    >
      <font-awesome-icon
        v-if="icons && icons[el.id-1].icon !== ''"
        :icon="icons[el.id-1].icon"
      />
      <p
        v-if="el.text !== undefined && el.text !== '' && translated"
        v-text="$t(el.text)"
      />
      <div
        v-else-if="el.text !== undefined && el.text !== '' && !translated"
        v-html="el.text"
      />
    </div>
    <hr>
    <a
      class="contact-link"
      :href="'mailto:contact@simply-frontend.com?subject='+$t('miscellaneous.mail-subject')"
    ><font-awesome-icon icon="envelope" /> write me</a>
  </div>
</template>

<script>
import anime from 'animejs';

export default {
  name: "SimpleTextBox",
  props: {
    headline: {
      type: String,
      default: 'Test Headline'
    },
    icons: {
      type: Array,
      default: () => [
        {id: 1, icon: ''},
        {id: 2, icon: ''}
      ]
    },
    texts: {
      type: Array,
      default: () => [
        {id: 1, text: 'Test 1'},
        {id: 2, text: 'Test 2'}
      ]
    },
    classes: {
      type: String,
      default: ''
    },
    translated: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    closeTextbox: function(el){
      let self = this;
      let textBox = el.target.closest('.border-wrapper');
      let buttons = document.querySelectorAll(
          '.button-text-element .border-wrapper:first-child'
      );

      if(textBox.classList.contains('toolbox')){
        let upperBoxWrapper = textBox.querySelector('.text-box-content:nth-of-type(2)');
        let lowerBoxWrapper = textBox.querySelector('.text-box-content:nth-of-type(8)');
        if(upperBoxWrapper.getAttribute('data-box-open') === 'true'){
          self.$parent.toolboxStartClosing(upperBoxWrapper, lowerBoxWrapper, 1);
          upperBoxWrapper.setAttribute('data-box-open', 'false');
          anime.remove(upperBoxWrapper);
          anime.remove(lowerBoxWrapper);
        } else{
          anime.remove(upperBoxWrapper);
          anime.remove(lowerBoxWrapper);
        }
      }

      let animation = anime({
        targets: textBox,
        maxHeight: 0,
        opacity: 0,
        duration: 150,
        easing: 'linear'
      });
      animation.finished.then(function(){
        if(textBox.classList.contains('toolbox')){
          let upperBoxWrapper = textBox.querySelector('.text-box-content:nth-of-type(2)');
          let lowerBoxWrapper = textBox.querySelector('.text-box-content:nth-of-type(8)');
          let upperClone = upperBoxWrapper.cloneNode(true);
          upperBoxWrapper.parentNode.replaceChild(upperClone, upperBoxWrapper);
          upperBoxWrapper.setAttribute('data-box-open', 'false');
          let lowerClone = lowerBoxWrapper.cloneNode(true);
          lowerBoxWrapper.parentNode.replaceChild(lowerClone, lowerBoxWrapper);
        }
        textBox.style.minHeight = '0';
        textBox.style.display = 'none';
        for(let btn of buttons)
        {
          btn.style.display = 'flex';
        }
        let animation = self.$parent.showButton(buttons, 350);
        animation.finished.then(function(){
          if(self.$parent.isMobile || window.innerWidth < 992){
            self.$parent.buttonIdle(buttons, 5000, [-7,7]);
          } else {
            self.$parent.buttonIdle(buttons, 5000, [-15,15]);
          }
        })
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/_variables.scss";
@import "./src/assets/_config.scss";
@import "./src/assets/_mixins.scss";
@import "./src/assets/_functions.scss";

  .border-wrapper{
    margin: 25px;
    padding: 0 15px 0 15px;
    border-width: 2px;
    display: flex;
    flex-flow: column;
    overflow-y: scroll;
    overflow-x: hidden;
    h2 {
      font-size: $font-size-base*2;
      margin: 15px 0 5px 0;
    }
    hr {
      width: 15%;
      margin: 0 auto 0 auto;
      &:last-of-type{
        margin-top: 40px;
      }
    }
    a{
      margin-top: 20px;
      font-family: map-get($font, family-var);
      font-size: $font-size-base*2;
      margin-bottom: 15px;
      &.contact-link{
        z-index: 1;
        svg {
          width: 24px;
          height: auto;
          display: none;
        }
      }
    }
    .text-box-content{
      display: flex;
      align-items: center;
      justify-content: left;
      padding: 20px;
      svg{
        padding-right: 20px;
        width: 1em;
      }
      p{
        margin: 0;
        font-size: $font-size-base*1.25;
      }
    }
    .btn-close{
      @each $key, $value in $themes {
        @if map-has-key($colorConfig, $key) {
          .#{$value} & {
            background-color: setStyle($colorConfig, $key, background);
          }
        } @else {
          @warn "The key ´#{$key} isn't defined in the map $config´"
        }
      }
      position: sticky;
      top: 0;
      width: 30px;
      padding-top: 15px;
      align-self: flex-end;
      border: none;
      z-index: 1;
      svg{
        @each $key, $value in $themes {
          @if map-has-key($colorConfig, $key) {
            .#{$value} & {
              stroke: setStyle($colorConfig, $key, color-primary);
            }
          } @else {
            @warn "The key ´#{$key} isn't defined in the map $config´"
          }
        }
      }
    }
  }
</style>