<template>
  <div class="language-changer">
    <div
      v-for="(lang, i) in langs"
      :key="`Lang${i}`"
      tabindex="0"
      role="button"
      aria-pressed="false"
      class="lang-wrapper"
      @click.prevent="languageClicked($event, lang)"
      @keypress="languageClicked($event, lang)"
    >
      <p class="lang-label">
        {{ lang }}
      </p>
      <hr>
    </div>
  </div>
</template>

<script>

export default {
  name: "LanguageSwitcher",
  data (){
    return { langs: ['en', 'de'] }
  },
  mounted (){
    let self = this;
    let lang = self.$i18n.locale;
    if(lang === 'en'){
      let langEl = document.querySelector('.lang-wrapper:nth-child(1) .lang-label');
      langEl.classList.add('active');
    } else{
      let langEl = document.querySelector('.lang-wrapper:nth-child(2) .lang-label');
      langEl.classList.add('active');
    }
  },
  methods: {
    languageClicked: function(el, lang){
      let textEl = el.target;
      let otherTexEl = '';
      if(!(textEl.classList.contains('lang-label'))){
          textEl = el.target.querySelector('.lang-label');
      }
      if(!(textEl.classList.contains('active'))){
        if(lang === 'en'){
          otherTexEl = textEl.closest('.language-changer').querySelector('.lang-wrapper:nth-child(2) .lang-label');
        } else if (lang === 'de'){
          otherTexEl = textEl.closest('.language-changer').querySelector('.lang-wrapper:nth-child(1) .lang-label');
        }
        this.$i18n.locale = lang;
        textEl.classList.add('active');
        otherTexEl.classList.remove('active');
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/_variables.scss";
@import "./src/assets/_config.scss";
@import "./src/assets/_mixins.scss";
@import "./src/assets/_functions.scss";

.language-changer{
  margin-top: 20px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  div{
    max-height: 42px;
  }
  .lang-wrapper{
    display: flex;
    &:focus{
      p{
        @each $key, $value in $themes {
          @if map-has-key($colorConfig, $key) {
            .#{$value} & {
              color: setStyle($colorConfig, $key, color-hover);
            }
          } @else {
            @warn "The key ´#{$key} isn't defined in the map $config´"
          }
        }
      }
    }
    p{
      font-size: $font-size-base*1;
      @each $key, $value in $themes {
        @if map-has-key($colorConfig, $key) {
          .#{$value} & {
            color: setStyle($colorConfig, $key, color-secondary);
          }
        } @else {
          @warn "The key ´#{$key} isn't defined in the map $config´"
        }
      }
      text-transform: uppercase;
      padding: 0 10px 0 10px;
      &:hover{
        cursor: pointer;
      }
      &.active{
        @each $key, $value in $themes {
          @if map-has-key($colorConfig, $key) {
            .#{$value} & {
              color: setStyle($colorConfig, $key, color-hover);
            }
          } @else {
            @warn "The key ´#{$key} isn't defined in the map $config´"
          }
        }
      }
    }
    hr{
      margin-top: 15px;
      height: 20px;
    }
    &:last-child{
      hr{
        display: none;
      }
    }
  }
}
</style>