<template>
  <div id="footer">
    <a
      :href="'mailto:contact@simply-frontend.com?subject='+$t('footer.mail-subject')"
      @mouseover="$parent.showIcon($event)"
      @mouseleave="$parent.hideIcon($event)"
      @focus="$parent.showIcon($event)"
      @focusout="$parent.hideIcon($event)"
    >
      <font-awesome-icon icon="envelope" />
      {{ $t('footer.contact') }}
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.linkedin.com/in/markus-albert-2051b61b2"
      @mouseover="$parent.showIcon($event)"
      @mouseleave="$parent.hideIcon($event)"
      @focus="$parent.showIcon($event)"
      @focusout="$parent.hideIcon($event)"
    >
      <font-awesome-icon :icon="['fab', 'linkedin']" />
      linkedin
    </a>
    <button
      tabindex="0"
      @click="showImpressum"
      @keypress="showImpressum"
    >
      {{ $t('footer.impressum') }}
    </button>
  </div>
</template>

<script>
import anime from 'animejs';

export default {
  name: "MyFooter",
  methods: {
    showImpressum: function(){
      let self = this;
      let impressumContainer = document.querySelector('#impressum');
      let buttons = document.querySelectorAll(
          '.button-text-element .border-wrapper:first-child'
      );
      let textBoxes = document.querySelectorAll(
        '.button-text-element .border-wrapper:nth-of-type(2)'
      );
      if(buttons.length > 0 && buttons.item(0).style.display === 'flex'){
        let i = 1;
        for(let btn of buttons){
          if(btn.style.display === 'flex'){
            if(i === buttons.length){
              let animation = self.$parent.hideButton(btn, 150);
              animation.finished.then(function(){
                btn.style.display = 'none';
                impressumContainer.style.display = 'flex';
                let textBoxMaxHeight = (window.innerHeight - 180)+'px';
                self.$parent.showTextbox(impressumContainer, 350, textBoxMaxHeight);
              });
            } else {
              let animation = self.$parent.hideButton(btn, 150);
              animation.finished.then(function(){
                btn.style.display = 'none';
              });
              i++;
            }
          }
        }
      } else {
        for(let textBox of textBoxes){
          if(textBox.style.display === 'flex'){
            if(textBox.classList.contains('toolbox')){
              let upperBoxWrapper = textBox.querySelector('.text-box-content:nth-of-type(3)');
              let lowerBoxWrapper = textBox.querySelector('.text-box-content:nth-of-type(4)');
              if(upperBoxWrapper.getAttribute('data-box-open') === 'true'){
                self.$parent.toolboxStartClosing(upperBoxWrapper, lowerBoxWrapper, 350);
                upperBoxWrapper.setAttribute('data-box-open', 'false');
              } else{
                anime.remove(upperBoxWrapper);
                anime.remove(lowerBoxWrapper);
              }

              let upperClone = upperBoxWrapper.cloneNode(true);
              upperBoxWrapper.parentNode.replaceChild(upperClone, upperBoxWrapper);

              let lowerClone = lowerBoxWrapper.cloneNode(true);
              lowerBoxWrapper.parentNode.replaceChild(lowerClone, lowerBoxWrapper);
            }
            let animation = anime({
              targets: textBox,
              maxHeight: 0,
              opacity: 0,
              duration: 150,
              easing: 'linear'
            });
            animation.finished.then(function(){
              textBox.style.display = 'none';
              impressumContainer.style.display = 'flex';
              let textBoxMaxHeight = (window.innerHeight - 180)+'px';
              self.$parent.showTextbox(impressumContainer, 350, textBoxMaxHeight);
            });
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/_variables.scss";
@import "./src/assets/_config.scss";
@import "./src/assets/_mixins.scss";
@import "./src/assets/_functions.scss";

  #footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button{
      padding: 0;
      background: none;
      border: none;
      @include break-for(medium){
        &:hover{
          cursor: pointer;
        }
      }
    }
    a, button{
      font-family: 'Roboto', sans-serif;
      margin: 0 10px 20px 0;
      @each $key, $value in $themes {
        @if map-has-key($colorConfig, $key) {
          .#{$value} & {
            color: setStyle($colorConfig, $key, color-footer);
          }
        } @else {
          @warn "The key ´#{$key} isn't defined in the map $config´"
        }
      }
      text-decoration: none;
      font-size: $font-size-base*1.25;
      svg{
        display: none;
        opacity: 0;
        padding-left: 5px;
      }
      &:hover, &:focus{
        @each $key, $value in $themes {
          @if map-has-key($colorConfig, $key) {
            .#{$value} & {
              color: setStyle($colorConfig, $key, color-hover);
            }
          } @else {
            @warn "The key ´#{$key} isn't defined in the map $config´"
          }
        }
      }
    }
  }
</style>