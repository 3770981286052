<template>
  <router-view />
</template>

<style lang="scss">
@import "./src/assets/_config.scss";
@import "./src/assets/_fonts.scss";
@import "./src/assets/_variables.scss";
@import "./src/assets/_mixins.scss";
@import "./src/assets/_functions.scss";

body{
  background-color: $black;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  @each $key, $value in $themes {
    @if map-has-key($colorConfig, $key) {
      .#{$value} &{
        color: setStyle($colorConfig, $key, color-primary);
      }
    } @else {
      @warn "The key ´#{$key} isn't defined in the map $config´"
    }
  }
}

h1, h2, h3, h4{
  font-family: map-get($font, family-head);
  font-weight: map-get($font, weight);
  &:hover{
    cursor: default;
  }
}

p{
  font-family: map-get($font, family-text);
  &:hover{
    cursor: default;
  }
}
hr{
  @each $key, $value in $themes {
    @if map-has-key($colorConfig, $key) {
      .#{$value} & {
        border-color: setStyle($colorConfig, $key, color-secondary);
      }
    } @else {
      @warn "The key ´#{$key} isn't defined in the map $config´"
    }
  }
  border-style: solid;
}
a{
  font-family: map-get($font, family-text);
  @each $key, $value in $themes {
    @if map-has-key($colorConfig, $key) {
      .#{$value} &{
        color: setStyle($colorConfig, $key, color-secondary);
      }
    } @else {
      @warn "The key ´#{$key} isn't defined in the map $config´"
    }
  }
  text-decoration: none;
  &:focus, &:active{
    text-decoration: none;
    @each $key, $value in $themes {
      @if map-has-key($colorConfig, $key) {
        .#{$value} &{
          color: setStyle($colorConfig, $key, color-hover);
        }
      } @else {
        @warn "The key ´#{$key} isn't defined in the map $config´"
      }
    }
  }
}
</style>
