export default {
  "text-box-1": {
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["let's design together"])},
    "text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile-first"])},
    "text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simple, with style"])},
    "text-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accessibility matters"])}
  },
  "text-box-2": {
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["let's bring your design to life"])},
    "text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue.js"])},
    "text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sass"])},
    "text-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Javascript"])},
    "text-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typo 3"])},
    "text-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopware"])}
  },
  "text-box-3": {
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my way of finishing up"])},
    "text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test early, test often"])},
    "text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["docker environments"])},
    "text-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CI/CD with Gitlab"])}
  },
  "footer": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
    "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["impressum"])},
    "mail-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, help us make our website look nice!"])}
  },
  "miscellaneous": {
    "mail-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello, we are looking for an awesome design for our new website!"])}
  }
}