<template>
  <div class="border-wrapper">
    <button>{{ msg }}</button>
  </div>
</template>

<script>

export default {
  name: "ActionButton",
  props: {
    msg: {
      type: String,
      default: 'button'
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./src/assets/_variables.scss";
@import "./src/assets/_config.scss";
@import "./src/assets/_mixins.scss";
@import "./src/assets/_functions.scss";

  .border-wrapper{
    width: 155px;
    height: 55px;
    display: flex;
    justify-content: center;
    > button{
        @each $key, $value in $themes {
          @if map-has-key($colorConfig, $key) {
            .#{$value} & {
              color: setStyle($colorConfig, $key, color-primary);
              background: setStyle($colorConfig, $key, background);
            }
          } @else {
            @warn "The key ´#{$key} isn't defined in the map $config´"
          }
        }
        width: 100%;
        font-family: map-get($font, family-head);
        font-size: $font-size-base*1.5;
        border: none;
        &:focus, &:active{
          @each $key, $value in $themes {
            @if map-has-key($colorConfig, $key) {
              .#{$value} & {
                border-color: setStyle($colorConfig, $key, color-primary);
              }
            } @else {
              @warn "The key ´#{$key} isn't defined in the map $config´"
            }
          }
          border: 1px solid;
        }
    }
  }

</style>
